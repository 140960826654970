
html {
  min-height: 100%;
  position: relative;
}

body {
  margin: 0;
  min-height: 105%;
}




footer {
  min-height: 50px;
  background-color: #191919;
  background-image: -moz-linear-gradient(top, #191919, #000000);
  background-image: -o-linear-gradient(top, #191919, #000000);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #191919), color-stop(1, #000000));
  background-image: -webkit-linear-gradient(#191919, #000000);
  background-image: linear-gradient(top, #191919, #000000);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#191919', endColorStr='#000000');
  border-top: 1px solid rgba(50, 50, 50, 0.3);
  width: 100%;
  /*position: relative;
  clear: both;             
  margin-top: 0px;            ELIMINADO
  padding-top: 0px;
  overflow: visible;*/
  position: fixed;
  bottom: 0;              /* NUEVO */
}

/*
.imgprev{
  height:250px;
}*/

.file-select {
  position: relative;
  display: inline-block;
}

.file-select::before {
  background-color: #ef5656;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  content: 'Seleccionar'; /* testo por defecto */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.file-select input[type="file"] {
  opacity: 0;
  width: 170px;
  height: 40px;
  display: inline-block;
}

#src-file1::before {
  content: 'Agregar imagen 1';
}

#src-file2::before {
  content: 'Agregar imagen 2';
}

#src-file3::before {
  content: 'Agregar imagen 3';
}

#src-file4::before {
  content: 'Agregar imagen 4';
}
